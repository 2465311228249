import { Link } from "gatsby";
import React from "react";
import { Container } from "reactstrap";

export interface TopStripPromotionProps {
  display: boolean;
  innerRef: any;
  sticky: boolean;
}

export const TopStripPromotion: React.FunctionComponent<TopStripPromotionProps> = (props: TopStripPromotionProps) => {
  return (
    <>
      <div
        ref={props.innerRef}
        className={`top-strip text-center ${props.display === true ? "open" : "closed"} ${
          props.sticky === true ? "" : "fixed"
        }`}
      >
        <Container>
          <div className="promotion d-none d-md-inline">
            <Link to="/education/foundational-course-bundle-psilocybin-mdma-ketamine/">
              <span className="fw400">50% off Course Bundle - </span>{" "}
              <span className="underline fw600">MDMA, Psilocybin, Ketamine</span>
            </Link>
          </div>
          <div className="promotion d-inline d-md-none">
            <Link to="/education/foundational-course-bundle-psilocybin-mdma-ketamine/">
              <span className="fw400">50% off Course Bundle - </span>{" "}
              <span className="underline fw600">MDMA, Psilocybin, Ketamine</span>
            </Link>
          </div>
        </Container>
      </div>
    </>
  );
};
